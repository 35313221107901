<template>
    <div class="app-container">
        <el-card class="mb15">
            <el-row>
                <el-col :span="24" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增群组</el-button>

                    <el-button class="ml20" type="primary" @click="downloadTemplate">下载导入成员模板</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">

                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="所属组织">
                            <el-tree-select @change="getList" clearable
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                                v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                                :render-after-expand="true" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    
                    <el-col :span="24" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.groupName" placeholder="请输入教研组名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    height="calc(100vh - 370px)" v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column label="群组名称" width="260" prop="groupName">
                        <template #default="scope">
                            <div :title="scope.row.groupName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.groupName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="160" label="群主姓名" prop="leadName" />
                    <el-table-column width="160" label="群主账号" prop="leadUsername" />
                    <el-table-column width="160" label="群组成员" prop="memberTxt">
                        <template #default="scope">
                            <div :title="scope.row.memberTxt" class="text-one">{{ scope.row.memberTxt }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" label="所属组织" prop="orgName" />
                    <el-table-column width="160" label="群组性质" prop="groupNatureName">
                    </el-table-column>
                    <el-table-column width="160" label="学段" prop="periodName" />
                    <el-table-column width="160" label="年级" prop="gradeName" />
                    <el-table-column width="160" label="学科" prop="subjectName" />
                    <el-table-column fixed="right" width="260" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="goDetail(scope.row)">查看
                        </el-button> -->
                            <div style="display: flex;" class="disnone">
                                <el-upload v-model:file-list="filesList" class="upload-demo"
                                    action="https://www.liyunjy.com/businessservice/teachingGroup/member/batchImport"
                                    :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                    :data="{ groupId: scope.row.groupId }" accept=".xlsx" :before-upload="beforeUpload">
                                    <el-button size="mini" type="primary" @click="excelAdd">导入成员</el-button>
                                </el-upload>
                                <el-button style="margin-left: 15px;" size="mini" type="primary" @click="handleUpdate(scope.row)">编辑
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定确定删除该群组" @confirm="handleDelete(scope.row)">
                                    <template #reference>
                                        <el-button size="mini" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import { orgListTree, groupListAll, groupDel } from "@/api/admin/system";
import { classLibraryListAll } from "@/api/admin/classLibrary";
import { famousCourseListFamous, famousCourseRelease, famousCourseDel, famousCourseListTreeFamous } from "@/api/admin/famousCourse"
import { trainListAll, trainRelease, trainStop } from "@/api/admin/train"
import { evaluationListAll, evaluationCreate, evaluationDel, evaluationRelease, evaluationStop, evaluationSubmit } from "@/api/admin/evaluation"
import { lessonList, lessonSubmit, lessonStop, lessonRelease, lessonGaFiles, delLessonFile } from "@/api/admin/lesson"
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            filesList: [],
            treeData: [],
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            token: this.$storage.get("token"),
            courseClassifyList: [],
            statusList: [
                { name: '初始', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [

            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                orgId: '',
                groupName: '',
                status: '',
                title: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                versionKey: '',
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getOrgListTree();
        this.getQueryDict();
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        downloadTemplate() {
            download('https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/excel/%E6%95%99%E7%A0%94%E7%BB%84%E6%88%90%E5%91%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '群组导入模板')
        },
        getOrgListTree() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.selectRow.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'class_version' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGAJaChange(e, type) {
            console.log(this.selectRow.gajaFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gajaFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAkjChange(e, type) {
            console.log(this.selectRow.gakjFiles)
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gakjFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        handleGAxtChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    let arr = []
                    this.selectRow.gaxtFiles.forEach(j => {
                        var fileUrl = ''
                        if (j.url) {
                            fileUrl = j.url
                        } else {
                            fileUrl = j.response.data
                        }
                        if (fileUrl != e.response.data) {
                            arr.push(new Promise(function (resolve, reject) {
                                delLessonFile({ fileUrl: fileUrl }).then(res => {
                                    resolve(res.data.code)
                                })
                            }))
                        }
                    })
                    Promise.all(arr).then((result) => {
                        this.getList()
                    })

                } else {

                }
            }
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            groupListAll().then(res => {
                if (this.queryParams.periodKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.periodKey == this.queryParams.periodKey
                    })
                }
                if (this.queryParams.gradeKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.gradeKey == this.queryParams.gradeKey
                    })
                }
                if (this.queryParams.subjectKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.subjectKey == this.queryParams.subjectKey
                    })
                }
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                if (this.queryParams.groupName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.groupName.toString().indexOf(this.queryParams.groupName) != -1
                    })
                }
                this.dataList = JSON.parse(JSON.stringify(res.data.data));
                this.dataList.forEach(e => {
                    var memberTxtList = [];
                    e.memberList.forEach(j => {
                        memberTxtList.push(j.memberName)
                    })
                    e.memberTxt = memberTxtList.join('、')
                })
                this.loading = false;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            groupDel({ groupId: row.groupId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/teachResearchDetail",
                query: {
                    groupId: row.groupId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/teachResearchAdd",
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/teachResearchAdd",
                query: {
                    groupId: row.groupId,
                }
            });
        },
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>