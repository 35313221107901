<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课题引领</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!topicId">新增课题引领</el-breadcrumb-item>
                <el-breadcrumb-item v-if="topicId">编辑课题引领</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题名称" prop="topicName">
                            <el-input v-model="form.topicName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题性质" prop="topicNature">
                            <el-select @change="changeTopicNature" style="width: 100%;" v-model="form.topicNature"
                                placeholder="请选择备课性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicNature != '1'">
                    <el-col :span="12">
                        <el-form-item :label="form.topicNature == 4 ? '群组' : '工作室'" prop="groupId">
                            <el-select @change="changeQun" style="width: 100%;" v-model="form.groupId" placeholder="请选择工作室/群组">
                                <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                                    :value="item.groupId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicNature && form.topicNature == '1'">
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题级别" prop="topicLevel">
                            <el-select @change="getOrgListTypeOrg" v-model="form.topicLevel" placeholder="请选择课题级别">
                                <el-option v-for="item in topicLevelList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题单位" prop="orgIdsList">
                            <el-select style="width: 100%;" multiple filterable v-model="form.orgIdsList"
                                placeholder="请选择课题单位">
                                <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName"
                                    :value="item.orgId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="展示类型" prop="topicType">
                            <el-radio-group v-model="form.topicType">
                                <el-radio :label="0">文字展示</el-radio>
                                <el-radio :label="1">图片展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题方向" prop="topicClassifyKey">
                            <el-select style="width: 100%;" v-model="form.topicClassifyKey" placeholder="请选择课题方向">
                                <el-option v-for="item in topicClassifyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey">
                                    <span style="float: left">{{ item.dictValue }}</span>
                                    <span @click.stop="delFx(item)" style="float: right; color: #8492a6; font-size: 13px">
                                        <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-icon @click="openLeadClassify" class="ml15 cursor" :size="30">
                            <CirclePlus />
                        </el-icon>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题负责人" prop="leader">
                            <!-- <el-select style="width: 100%;" filterable v-model="form.leader" placeholder="请选择课题负责人">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                            <choosePeople :showTitle="'选择人员'" :radio="true" ref="leader" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="参与总人数" prop="totalCount">
                            <el-input-number v-model="form.totalCount" step-strictly :min="1" :max="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 0">
                    <el-col :span="12">
                        <el-form-item label="课题介绍" prop="recommend">
                            <el-input type="textarea" maxlength="1000" v-model="form.recommend" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申报时间" prop="applyFormTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startApplyDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.applyBeginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endApplyDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.applyEndTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开题时间" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.beginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="审核人员" prop="checkUsersList">
                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.checkUsersList"
                                placeholder="请选择审核人员">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                            <choosePeople :showTitle="'选择人员'" ref="checkUsersList" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 0">
                    <el-col :span="12">
                        <el-form-item label="研究目的" prop="studyAim">
                            <el-input type="textarea" maxlength="1000" v-model="form.studyAim" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 0">
                    <el-col :span="12">
                        <el-form-item label="研究方法" prop="studyMethod">
                            <el-input type="textarea" maxlength="1000" v-model="form.studyMethod" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="其他" prop="remarks">
                            <el-input type="textarea" maxlength="1000" v-model="form.remarks" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12" v-if="form.topicType != 1">
                    <el-form-item label="课题引领量表" prop="scaleIds">
                        <el-select style="width:100%" multiple filterable v-model="form.scaleList" placeholder="请选择课题引领量表">
                            <el-option v-for="item in rateScaleList" :key="item.scaleId" :label="item.title"
                                :value="item.scaleId">
                                <span style="float: left">{{ item.title }}</span>
                                <span @click.stop="preview(item)" style="float: right; color: #8492a6; font-size: 13px">
                                    <el-icon>
                                        <View />
                                    </el-icon></span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/topic/lead/uploadFile"
                                :on-change="handleJAChange" :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx"
                                :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 1">
                    <el-col :span="12">
                        <el-form-item label="导入图片" prop="imgUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.liyunjy.com//businessservice/topic/lead/uploadCover"
                                :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:60px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'创建课题方向'" v-model="topicClassifyShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="orgName">
                        <el-input v-model="classifyName" placeholder="最多16个字" maxlength="16" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitTopicClassifyForm">确 定</el-button>
                    <el-button @click="topicClassifyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup, deleteDict, groupListAuthGroup } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleList, rateScaleInfo } from "@/api/admin/evaluation";
import { leadAddClassify, leadCreate, leadDeleteFile, leadUpdate, leadInfo } from "@/api/admin/lead";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            topicClassifyShow: false,
            classifyName: '',
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            topicId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            topicClassifyList: [],
            orgList: [],
            topicLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                groupId: '',
                topicNature: '1',
                topicName: '',
                topicLevel: '',
                topicType: 0,
                topicClassifyKey: '',
                leader: '',
                totalCount: 10,
                recommend: '',
                checkUsersList: [],
                orgIdsList: [],
                trainLevel: '',
                beginTime: '',
                endTime: '',
                applyBeginTime: '',
                applyEndTime: '',
                studyAim: '',
                studyMethod: '',
                remarks: '',
                fileUrls: '',
                imgUrl: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                scaleIds: '',
                scaleList: [],
            },
            groupList: [],
            natureList: [],
            // 表单校验
            rules: {
                topicNature: [
                    { required: true, message: "请选择课题性质", trigger: "change" },
                ],
                groupId: [
                    { required: true, message: "请选择工作室/群组", trigger: "change" },
                ],
                // periodKey: [
                //     { required: true, message: "请选择学段", trigger: "change" },
                // ],
                // gradeKey: [
                //     { required: true, message: "请选择年级", trigger: "change" },
                // ],
                // subjectKey: [
                //     { required: true, message: "请选择学科", trigger: "change" },
                // ],
                checkUsersList: [
                    { required: true, validator: this.checkcheckUsersList },
                ],
                orgIdsList: [
                    { required: true, message: "请选择课题单位", trigger: "change" },
                ],
                topicName: [
                    { required: true, message: "请输入课题名称", trigger: "blur" },
                ],
                topicClassifyKey: [
                    { required: true, message: "请选择课题方向", trigger: "change" },
                ],
                topicLevel: [
                    { required: true, message: "请选择课题级别", trigger: "change" },
                ],
                totalCount: [
                    { required: true, message: "请选择参与总人数", trigger: "change" },
                ],
                topicType: [
                    { required: true, message: "请选择课题类型", trigger: "change" },
                ],
                trainLevel: [
                    { required: true, message: "请选择培训级别", trigger: "change" },
                ],
                leader: [
                    { required: true, message: "请选择课题负责人", trigger: "change" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
                applyFormTime: [
                    { required: true, validator: this.checkApplyValidator }
                ],
                imgUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ]
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            rateScaleList: [],
            previewData: {},
            previewShow: false,
        };
    },
    created() {

        this.getRateScaleList();
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.topicId) {
            this.topicId = this.$route.query.topicId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
                this.form.topicNature = ''
            }
            this.isShow = true
        }
    },
    unmounted() {
        if (this.topicId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        leadDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    leadDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        delFx(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该课题方向?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                deleteDict({ dicId: item.dicId }).then(res => {
                    if (res.data.code == 1) {
                        if (_this.form.topicClassifyKey == item.dictKey) {
                            _this.form.topicClassifyKey = ''
                        }
                        _this.$message.success('删除成功')
                        queryDict({ code: 'topic_classify' }).then(res => {
                            _this.topicClassifyList = res.data.data;
                        })
                    } else {
                        _this.$message.error('操作失败')
                    }
                })
            }).catch(() => {

            })
        },
        checkcheckUsersList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.checkUsersList.checkList.length == 0) {
                callback(new Error('请选择审核人员'))
            } else {
                callback()
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getRateScaleList() {
            rateScaleList({ scaleType: 3 }).then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        changeTopicNature(e) {
            console.log(e);
            this.groupList = [];
            // if (!this.topicId) {
            this.form.groupId = '';
            // }
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 1) {
                this.sxKj()
                this.$refs.leader.getUserList()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 2) {
                groupListAuthGroup({ groupNature: 2 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.leader.getUserList()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 3) {
                groupListAuthGroup({ groupNature: 3 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.leader.getUserList()
                this.$refs.checkUsersList.getUserList()
            } else if (e == 4) {
                this.sxKj()
                groupListAuthGroup({ groupNature: 1 }).then(res => {
                    this.groupList = res.data.data;
                })
            }
        },
        sxKj() {
            this.$refs.leader.checkList = []
            this.$refs.checkUsersList.checkList = []
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.imgUrl != '') {
                callback()
            } else {
                callback(new Error('请上传导入图片'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.imgUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        openLeadClassify() {
            this.classifyName = ''
            this.topicClassifyShow = true;
        },
        submitTopicClassifyForm() {
            leadAddClassify({ classifyName: this.classifyName }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryDict();
                    this.$message.success('添加成功')
                    this.topicClassifyShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        changeQun(e) {
            console.log(this.form.groupId);
            // if (this.form.topicNature == 4) {
                this.sxKj()
                this.$refs.leader.getQunPeople(this.form.groupId)
                this.$refs.checkUsersList.getQunPeople(this.form.groupId)
            // }
        },
        getlessonInfo() {
            this.form.scaleList = [];
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                leadInfo({ topicId: this.topicId }).then(res => {
                    this.changeTopicNature(res.data.data.topicNature)
                    this.form = res.data.data;
                    this.$refs.leader.checkList = this.form.leaderList;
                    this.$refs.checkUsersList.checkList = this.form.checkUserList;
                    if (this.form.topicNature == 2 || this.form.topicNature == 3 ||this.form.topicNature == 4) {
                        this.$refs.leader.getQunPeople(this.form.groupId)
                        this.$refs.checkUsersList.getQunPeople(this.form.groupId)
                    }
                    // this.form.checkUsersList = this.form.checkUsers.split(',')
                    this.form.topicLevel = this.form.topicLevel + ''
                    this.form.orgIdsList = res.data.data.orgIds.split(',')
                    this.form.scaleList = this.form.scaleIds && this.form.scaleIds.split(',')
                    orgListTypeOrg({ orgType: this.form.topicLevel }).then(res => {
                        this.orgList = res.data.data;
                    })
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.isShow = true;
                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleKjChange(file) {
            console.log(file)
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e >= this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startApplyDateConfirm(e) {
            if (e >= this.form.applyEndTime) {
                this.form.applyEndTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e >= this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e >= this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endApplyDateConfirm(e) {
            if (e <= this.form.applyBeginTime) {
                this.form.applyBeginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else if (this.form.beginTime < this.form.applyEndTime) {
                callback(new Error('开题时间不能小于申报时间'))
            } else {
                callback()
            }
        },
        checkApplyValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.applyBeginTime === '' || !this.form.applyBeginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.applyEndTime === '' || !this.form.applyEndTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            this.form.orgIdsList = [];
            orgListTypeOrg({ orgType: this.form.topicLevel }).then(res => {
                this.orgList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'topic_classify' }).then(res => {
                this.topicClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.topicLevelList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
                // if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
                //     this.natureList.splice(0, 1)
                // }
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.form.checkUsersList = [];
            this.form.applyBeginTime = this.setHms(this.form.applyBeginTime);
            this.form.applyEndTime = this.setHms(this.form.applyEndTime);
            this.form.beginTime = this.setHms(this.form.beginTime);
            this.form.endTime = this.setHms(this.form.endTime);

            if (this.$refs.leader.checkList.length != 0) {
                this.form.leader = this.$refs.leader.checkList[0].userId
            } else {
                this.form.leader = ''
            }
            this.$refs.checkUsersList.checkList.forEach(e => {
                this.form.checkUsersList.push(e.userId)
            })
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.form.checkUsers = this.form.checkUsersList.toString()
            this.form.orgIds = this.form.orgIdsList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    if (obj.scaleList) {
                        obj.scaleIds = obj.scaleList.toString();
                    }
                    if (obj.topicType == 1) {
                        obj.scaleIds = '';
                    }

                    delete obj.checkUsersList
                    delete obj.orgIdsList
                    if (this.topicId) {
                        leadUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    leadDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/lead', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        leadCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    leadDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/lead', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/lead')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>