<template>
  <div id="home-box">
    <el-container>
      <!--顶部菜单-->
      <el-header style="background: #3859df;">
        <el-row type="flex" align="middle">
          <!--左侧组织切换-->
          <el-col :span="21">
            <div class="disflex-al-ct">
              <div class="zbh-logo" :style="isActive ? 'width:0px;' : 'width:200px;'">
                <img v-if="userInfo.orgNatureName && userInfo.orgNatureName == '励耘教研' && !isActive" style="width: 50px;"
                  src="../assets/lyjy.png" alt="">
                <div :title="userInfo.orgNatureName" class="text-one"
                  style="color: #fff;width: 160px;overflow: hidden;text-align: left;margin-left: 10px;" v-if="!isActive">
                  {{ userInfo.orgNatureName }}</div>
              </div>
              <!-- <el-dropdown @command="changeGroup" trigger="click">
                <el-button type="primary">
                  {{ account.groupName }}
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(item, index) in accountList" :command="item" :key="index">
                      {{ item.groupName }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown> -->
              <!-- <div class="" @click="toggleClick">
                <svg :class="{ 'is-active': isActive }" class="hamburger" viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg" width="64" height="64">
                  <path fill="#fff"
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                </svg>
              </div> -->
              <div style="flex: 1;" class="top-tp">
                <el-menu class="el-menu-demo" mode="horizontal" background-color="transparent"
                  active-background-color="#fff" :default-active="activePath" text-color="#ccd2ff"
                  active-text-color="#fff" @select="handleSelect">
                  <el-menu-item @click="goHome">
                    首页</el-menu-item>
                  <!-- <el-menu-item index="systemManage" @click="saveNavState('systemManage')">
                    系统管理</el-menu-item> -->
                  <!-- <el-menu-item index="personApp" @click="saveNavState('personApp')">
                    用户中心</el-menu-item> -->
                  <el-menu-item index="famousCourseManage" @click="saveNavState('famousCourseManage')">
                    资源中心</el-menu-item>
                  <el-menu-item index="lessonManage" @click="saveNavState('lessonManage')">
                    一体化教研</el-menu-item>
                  <el-menu-item index="teachActiManage" @click="saveNavState('teachActiManage')">
                    教科研</el-menu-item>
                  <el-menu-item index="teacherTrainManage" @click="saveNavState('teacherTrainManage')">
                    师资培训</el-menu-item>
                  <el-menu-item index="famousPrincipal" @click="saveNavState('famousPrincipal')">
                    名校长工作室</el-menu-item>
                  <!-- <el-menu-item index="4343">
                    名校长工作室</el-menu-item> -->
                  <el-menu-item index="teachStudio" @click="saveNavState('teachStudio')">
                    名师工作室</el-menu-item>

                  <!-- <el-menu-item index="customerManage" @click="saveNavState('customerManage')">
                    群组管理</el-menu-item> -->
                </el-menu>
              </div>

            </div>

          </el-col>
          <!--个人中心及消息通知-->
          <el-col :span="3">
            <el-row justify="end" type="flex" align="middle">
              <!-- <el-col :span="2" :xl="1">
                <svg t="1652837604915" class="icon cursor" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="5754" width="20" height="20">
                  <path
                    d="M861.575529 783.058824a30.117647 30.117647 0 0 1-30.117647-30.117648V378.096941C831.457882 202.541176 689.152 60.235294 513.626353 60.235294 338.070588 60.235294 195.764706 202.541176 195.764706 378.096941V752.941176a30.117647 30.117647 0 0 1-30.117647 30.117648H105.411765v60.235294h813.17647v-60.235294h-57.012706zM918.588235 722.823529a60.235294 60.235294 0 0 1 60.235294 60.235295v60.235294a60.235294 60.235294 0 0 1-60.235294 60.235294H105.411765a60.235294 60.235294 0 0 1-60.235294-60.235294v-60.235294a60.235294 60.235294 0 0 1 60.235294-60.235295h30.117647V378.096941C135.529412 169.261176 304.790588 0 513.626353 0c208.805647 0 378.066824 169.261176 378.066823 378.096941V722.823529H918.588235z m-481.882353 210.82353h180.705883a90.352941 90.352941 0 0 1-180.705883 0z"
                    fill="#2489f2" p-id="5755"></path>
                </svg>
              </el-col> -->
              <!-- <div>
                <el-popover class="cursor ml15" placement="top-start" trigger="hover" content="点击切换校区">
                  <template #reference>
                    <div @click="changeshowChangeCampus" class="text-one col-fff">{{ schoolName }}</div>
                  </template>
                </el-popover>
              </div> -->

              <el-col :span="24" :xl="24">
                <div class="tr-img">
                  <el-badge :is-dot="messageBol" class="item">
                    <img class="cursor" @click="toPage('personApp', 'messageCenter')" src="../assets/homePage/rw.png"
                      alt="">
                  </el-badge>
                  <el-badge :is-dot="systemBol" class="item">
                    <img class="cursor" @click="toPage('personApp', 'systemCenter')" src="../assets/homePage/xx.png"
                      alt="">
                  </el-badge>

                  <el-dropdown class="cursor">
                    <el-avatar style="margin-left: 15px;" :size="30" :src="userHeadImg + '?_=' + Date.now()" />
                    <template #dropdown>
                      <el-dropdown-menu>
                        <div @click="toPage('personApp', 'userInfo')" class="cursor" style="text-align: center;">{{
                          popname }}</div>
                        <!-- <el-dropdown-item @click="openJs">打开教师助手</el-dropdown-item> -->
                        <el-dropdown-item style="text-align: center;" @click="logout">安全退出</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <!-- <el-dropdown class="cursor">
                  <el-avatar :size="35" :src="userHeadImg + '?_=' + Date.now()" />
                  <template #dropdown>
                    <el-dropdown-menu>
                      <div @click="toPage('personApp', 'userInfo')" class="cursor" style="text-align: center;">{{ popname
                      }}</div>
                      <el-dropdown-item style="text-align: center;" @click="logout">安全退出</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown> -->
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside class="lftop" :style="isActive ? 'width:0px;' : 'width: 200px;'">

          <el-menu :collapse-transition="false" :collapse="isActive" router unique-opened active-text-color="#fff"
            :default-active="activeSecondPath" class="el-menu-vertical-demo" background-color="#fff" text-color="#000"
            @open="handleOpen" @close="handleClose">
            <!-- <div class="hamburgerBox">
              <div class="col-fff" v-if="!isActive" :style="isActive ? 'color:#20222a;' : 'color:#ffffff;'">群组管理平台</div>
              <div class="" @click="toggleClick">
                <svg style="filter: invert(1);" :class="{ 'is-active': isActive }" class="hamburger"
                  viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64">
                  <path
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                </svg>
              </div>
            </div> -->
            <el-menu-item v-for="item in menusList[activePath]" :route="{ path: item.path, query: item.query }"
              :index="item.path" @click="saveSecondNavState(item.path)">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.title }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>


        <!--侧边栏-->


        <!--展示区-->
        <el-main>
          <!-- <tags-view /> -->
          <!-- <keep-alive>
            <router-view></router-view>
          </keep-alive> -->
          <div class="cursor" @click="toggleClick" style="position: absolute;top: 50%;transform: translateY(-50%);">
            <img style="width: 18px;"
              :src="isActive ? require('../assets/homePage/toZk.png') : require('../assets/homePage/toSx.png')" alt="">
          </div>
          <router-view v-slot="{ Component, route }">
            <keep-alive>
              <component @changeInfo="changeInfo" @changeImg="changeAvater" :key="route.name" :is="Component"
                @changeCtMessage="getMessage" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component @changeInfo="changeInfo" @changeImg="changeAvater" :key="route.name" :is="Component"
              @changeCtMessage="getMessage" v-if="!$route.meta.keepAlive" />
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-dialog :title="'选择校区'" v-model="showChangeCampus" width="40%">
      <el-radio v-model="schoolId" v-for="item in schoolList" :key="item.schoolId" :label="Number(item.schoolId)"
        border>{{
          item.schoolName }}</el-radio>
      <template #footer>
        <div class="disflex-row-center">
          <el-button type="primary" @click="changeSchool">确 定</el-button>
          <el-button @click="showChangeCampus = false">取 消</el-button>
        </div>
      </template>
    </el-dialog> -->
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'

import global from "@/utils/global"
import Screenfull from '@/components/Screenfull'
import Breadcrumb from '@/components/Breadcrumb'
import { querySchoolList, oauthCode } from "@/api/user/login";
import TagsView from '@/components/TagsView/index.vue'
import { userMessages, } from "@/api/admin/message"

export default {
  components: {
    Screenfull,
    Breadcrumb,
    TagsView
  },
  name: "groupHome",
  data() {
    return {
      schoolId: '',
      schoolList: [],
      showChangeCampus: false,
      activePath: "",
      activeSecondPath: "",
      userHeadImg: "",
      userInfo: {},
      menusList: {
        systemManage: [

          // { title: '组织管理', path: 'arrayMaintenance', icon: 'noteBook' },
          // { title: '群组管理', path: 'teachResearch', icon: 'noteBook' },
          // { title: '用户管理', path: 'uesrManage', icon: 'noteBook' },
          { title: '新闻资讯', path: 'news', icon: 'noteBook' },
          { title: '头部banner', path: 'banner', icon: 'noteBook' },
          { title: '活动banner', path: 'bannerNotice', icon: 'noteBook' },
          { title: '工作简报', path: 'workBrieManage', icon: 'noteBook' },
          // { title: '教程库', path: 'classLibrary', icon: 'noteBook' },
        ],
        personApp: [
          { title: '个人中心', path: 'userInfo', icon: 'noteBook' },
          { title: '消息中心', path: 'systemCenter', icon: 'noteBook' },
          { title: '任务中心', path: 'messageCenter', icon: 'noteBook' },
          { title: '课程收藏', path: 'collectionCenter', icon: 'noteBook' },
          { title: '我的关注', path: 'myFollow', icon: 'noteBook' }
          // { title: '成长中心', path: '', icon: 'noteBook' },
        ],
        famousCourseManage: [
          // { title: '听评课量表', path: 'evaluationAdd', icon: 'messageBox' },
          // { title: '资源列表', path: 'famousList', icon: 'noteBook' },
          { title: '高水平示范课', path: 'famousGspList', icon: 'noteBook', query: { stageType: 1 } },
          { title: '区本课程', path: 'famousQbList', icon: 'noteBook', query: { stageType: 2 } },
          { title: '校本课程', path: 'famousJbList', icon: 'noteBook', query: { stageType: 3 } },
          { title: '课后服务', path: 'famousKhList', icon: 'noteBook', query: { stageType: 4 } },
          { title: '人工智能', path: 'famousAIList', icon: 'noteBook', query: { stageType: 5 } },
          { title: '金牌助教（小学）', path: 'ldzy', icon: 'noteBook' },
          { title: '人文美育', path: 'rwmy', icon: 'noteBook' },
          { title: '国家智慧教育平台', path: 'gjb', icon: 'noteBook' },
          // { title: '资源管理', path: 'famousCourse', icon: 'noteBook' },
        ],
        lessonManage: [
          { title: '一体化教研统计', path: 'ptStatistics', icon: 'noteBook' },

          { title: '个人备课', path: 'myLess', icon: 'noteBook' },
          { title: '协同备课', path: 'lesson', icon: 'noteBook' },
          // { title: '备课审核', path: 'shareApply', icon: 'noteBook' },
          { title: '已完备课', path: 'lessttShare', icon: 'noteBook' },
          { title: '听评课', path: 'evaluation', icon: 'noteBook' },
          // { title: '作业管理', path: '', icon: 'noteBook' },
          { title: '教师助手', path: 'zbnote', icon: 'noteBook' },
        ],
        teachActiManage: [
          { title: '教科研统计', path: 'jkyStatistics', icon: 'noteBook' },

          { title: '课题引领', path: 'lead', icon: 'noteBook' },
          { title: '教研赛事', path: 'race', icon: 'noteBook' },
          { title: '专题活动', path: 'activity', icon: 'noteBook' },

          { title: '申报审核', path: 'topicApply', icon: 'noteBook' },

          // { title: '赛事审核', path: 'handleApply', icon: 'noteBook' },
          { title: '成果展示', path: 'achieve', icon: 'noteBook' },
          // { title: '教科研统计', path: 'jkyStatistics', icon: 'noteBook' }
        ],
        teacherTrainManage: [
          { title: '师资培训统计', path: 'szpxStatistics', icon: 'noteBook' },
          { title: '师资培训课程', path: 'traCourseList', icon: 'noteBook' },

          // { title: '用户中心', path: 'userInfo', icon: 'noteBook' },

          // { title: '新增培训视频', path: 'videoLibraryAdd', icon: 'messageBox' },
          // { title: '培训视频', path: 'videoLibrary', icon: 'messageBox' },

          // { title: '听评课量表', path: 'rateScale', icon: 'messageBox' },
          // { title: '师资培训统计', path: 'szpxStatistics', icon: 'noteBook' },
          { title: '师资培训', path: 'train', icon: 'noteBook' },
          // { title: '培训视频管理', path: 'traCourse', icon: 'noteBook' },
        ],
        famousPrincipal: [
          { title: '工作室列表', path: 'studioList', icon: 'noteBook' },
          { title: '工作室管理', path: 'studioManage', icon: 'noteBook' },
          // { title: '名校长工作室统计', path: 'principalStudio', icon: 'noteBook' }
        ],
        teachStudio: [
          { title: '工作室列表', path: 'teachStudioList', icon: 'noteBook' },
          { title: '工作室管理', path: 'teachStudioManage', icon: 'noteBook' },
          // { title: '名师工作室统计', path: 'msgzsStatistics', icon: 'noteBook' }
        ],
        customerManage: [
          { title: '群组维护', path: 'groupSet', icon: 'noteBook' },
        ],
      },
      accountList: [],
      account: {},
      global: global,
      logoTextWidth: 200,   // 左上角logo文字宽度
      isActive: false,      // 是否菜单折叠
      isAuth: this.$storage.get('isAuth'),
      isSuperAuth: this.$storage.get('isSuperAuth'),
      issuedBookAuth: this.$storage.get('issuedBookAuth'),
      popname: '',
      messageBol: false,
      systemBol: false,
      isSetInter: null,
      // schoolName: '',
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
  },
  watch: {
    $route(to, from) {
      console.log('to', to)
    },
  },
  created() {
    clearInterval(this.$store.state.zdy.isSetInter1)
    clearInterval(this.$store.state.zdy.isSetInter2)
    this.getMessage()
    this.$store.state.zdy.isSetInter2 = setInterval(() => {
      this.getMessage()
    }, 30000)

    // this.isSetInter = setInterval(() => {
    //   console.log(2);

    //   this.getMessage()
    // }, 5000)
    this.$store.commit("zdy/setRole", this.$storage.get("role"));

    if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2) {
      this.menusList.systemManage.unshift({ title: '组织', path: 'arrayMaintenance', icon: 'noteBook' }, { title: '群组', path: 'teachResearch', icon: 'noteBook' }, { title: '用户', path: 'uesrManage', icon: 'noteBook' })
      this.menusList.systemManage.forEach(e => {
        e.title = e.title + '管理'
      })
      this.menusList.famousCourseManage.push(
        { title: '资源管理', path: 'famousCourse', icon: 'noteBook' },
      )
    }
    if (this.$storage.get("role") == 3) {
      this.menusList.systemManage.unshift(
        { title: '组织管理', path: 'arrayMaintenance', icon: 'noteBook' },
        { title: '群组管理', path: 'teachResearch', icon: 'noteBook' }
        , { title: '用户管理', path: 'uesrManage', icon: 'noteBook' }
      )
      // this.menusList.famousCourseManage.push(
      //   { title: '资源管理', path: 'famousCourse', icon: 'noteBook' },
      // )
    }
    // this.menusList.systemManage.unshift({ title: '我的关注', path: 'myFollow', icon: 'noteBook' })

    if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2 || this.$storage.get("role") == 3) {
      this.menusList.systemManage.push({ title: '教程库管理', path: 'classLibrary', icon: 'noteBook' })
      // this.menusList.teachActiManage.unshift({ title: '教科研统计', path: 'jkyStatistics', icon: 'noteBook' })
      // this.menusList.teacherTrainManage.unshift({ title: '师资培训统计', path: 'szpxStatistics', icon: 'noteBook' })
      this.menusList.teacherTrainManage.push({ title: '培训视频管理', path: 'traCourse', icon: 'noteBook' })
      this.menusList.famousPrincipal.unshift({ title: '名校长工作室统计', path: 'principalStudio', icon: 'noteBook' })
      this.menusList.teachStudio.unshift({ title: '名师工作室统计', path: 'msgzsStatistics', icon: 'noteBook' })
      this.menusList.lessonManage.splice(3, 0, { title: '备课审核', path: 'shareApply', icon: 'noteBook' })
      // this.menusList.lessonManage.unshift({ title: '一体化教研统计', path: 'ptStatistics', icon: 'noteBook' })
    }
    if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
      this.menusList.systemManage.push({ title: '教程库管理', path: 'classLibrary', icon: 'noteBook' })
      this.menusList.famousPrincipal.unshift({ title: '名校长工作室统计', path: 'principalStudio', icon: 'noteBook' })
      this.menusList.teachStudio.unshift({ title: '名师工作室统计', path: 'msgzsStatistics', icon: 'noteBook' })
    }
    this.logoTextWidth = window.innerWidth * (20 / 100);
    this.watchWidth();
    if (window.sessionStorage.getItem('activePath') && window.sessionStorage.getItem('activePath') != 'undefined') {
      this.activePath = window.sessionStorage.getItem('activePath')
    } else {
      console.log(Object.keys(this.menusList))
      this.activePath = Object.keys(this.menusList)[0]
      window.sessionStorage.setItem('activePath', Object.keys(this.menusList)[0])
    }
    if (window.sessionStorage.getItem('activeSecondPath') && window.sessionStorage.getItem('activeSecondPath') != 'undefined') {
      this.activeSecondPath = window.sessionStorage.getItem('activeSecondPath')
      console.log(222222, this.activeSecondPath)
    } else {
      console.log(Object.keys(this.menusList))
      this.activeSecondPath = this.menusList[Object.keys(this.menusList)[0]][0].path
      window.sessionStorage.setItem('activeSecondPath', this.menusList[Object.keys(this.menusList)[0]][0].path)
    }
    if (this.activeSecondPath == 'famousGspList') {
      this.replace('/' + this.activeSecondPath, { stageType: 1 })
    } else {
      this.replace('/' + this.activeSecondPath)
    }

    this.popname = JSON.parse(this.$storage.get("userInfo")).trueName
    this.userHeadImg = JSON.parse(this.$storage.get("userInfo")).avatar
    // this.schoolName = this.$storage.get("schoolName")
    this.userInfo = JSON.parse(this.$storage.get("userInfo"))
    // this.accountList = JSON.parse(this.$storage.get("groupAccounts"))
    // this.account = JSON.parse(this.$storage.get("lastGroupAccount"))
    // this.userHeadImg = this.global.fileUrl + "/user/head/" + this.userInfo.userId + ".png_original?" + new Date().getTime()
  },
  mounted() {
    // 监听网页大小
    window.onresize = () => {
      return (() => {
        this.watchWidth();
        console.log(window.innerWidth)
        this.logoTextWidth = window.innerWidth * (20 / 100);
      })();
    };
  },
  methods: {
    getMessage() {
      userMessages({ code: 1 }).then(res => {
        this.systemBol = false;
        res.data.data.forEach(e => {
          if (e.isRead == 0) {
            this.systemBol = true;
          }
        })
      });
      userMessages().then(res => {
        this.messageBol = false;
        res.data.data.forEach(e => {
          if (e.isRead == 0) {
            if (e.code != 1) {
              this.messageBol = true;
            }
          }
        })
      });
    },
    changeAvater(e) {
      this.userHeadImg = null;
      console.log(23123, e);
      this.userHeadImg = e;
    },
    changeInfo(e) {
      this.popname = JSON.parse(this.$storage.get("userInfo")).trueName
      this.userHeadImg = JSON.parse(this.$storage.get("userInfo")).avatar
    },
    openJs() {
      // var xmlHttp; 
      // debugger
      // if (window.ActiveXObject) { 
      //   xmlHttp = new ActiveXObject("WScript.Shell"); 
      // } else if (window.XMLHttpRequest) { 
      //   xmlHttp = new XMLHttpRequest(); 
      // }
      // try {
      //   var obj = new ActiveXObject("WScript.Shell");
      //   var s = "HKEY_CURRENT_USER\\Software\\TopTeam\\Client\\Installed";
      //   var sNic = obj.RegRead(s);
      //   if (sNic != null) {
      //     obj.run("C:\\Progra~1\\Kama\\TPClient\\TP_Client.exe");
      //   }
      // } catch (e) {
      //   console.log(e)
      //   // alert("本机没有安装客户端，需要下载安装！");
      //   // window.open('');
      // }
      var openUrl = 'zbnote:' + this.$storage.get("token")
      var _this = this;
      window.sessionStorage.setItem('activeSecondPath', _this.menusList[_this.activePath][0].path)
      this.activeSecondPath = _this.menusList[_this.activePath][0].path
      _this.$router.push({ path: '/' + _this.menusList[_this.activePath][0].path });
      window.protocolCheck(openUrl, () => {
        // alert('检测到未安装客户端，请确认是否下载?')
        ElMessageBox.confirm(
          '检测到未安装客户端，请确认是否下载?',
          '下载',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            // window.open('http://47.93.86.37:8777/aide/ZBnote.exe', '_blank')
            window.open('https://www.liyunjy.com/aide/ZBnote.exe', '_blank')

          })
          .catch(() => {
            _this.$router.push({ path: '/' + _this.menusList[_this.activePath][0].path });
          })
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    changeshowChangeCampus() {
      querySchoolList().then(res => {
        this.schoolList = res.data.data;
        this.schoolId = Number(this.$storage.get("schoolId"))
        this.showChangeCampus = true;
      })
    },
    changeSchool() {
      this.schoolList.forEach(e => {
        if (e.schoolId == this.schoolId) {
          this.$storage.set('schoolName', e.schoolName);
          this.$storage.set('schoolId', e.schoolId);
        }
      })
      this.showChangeCampus = false;
      console.log(this.schoolId)
    },
    // 监听网页大小
    watchWidth() {
      if (window.innerWidth < 1000) {
        this.isActive = true;
        this.$store.commit("zdy/setIsActive", true);
      } else {
        this.isActive = false;
        this.$store.commit("zdy/setIsActive", false);
      }
    },
    toggleClick() {
      setTimeout(() => {
        this.isActive = !this.isActive;
        this.$store.commit("zdy/setIsActive", this.isActive);
        console.log(this.$store.state.zdy.isActive)
      }, 10)

    },
    handleOpen: function () { },
    handleClose: function () { },
    changeGroup(account) {
      this.account = account
      this.$storage.set("lastGroupAccount", JSON.stringify(account));
      this.$addStorageEvent("saasGroup", account);
    },
    goHome() {
      this.$router.push({ path: '/homePage' });
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      if (this.menusList[this.activePath][0].query) {
        this.$router.push({ path: '/' + this.menusList[this.activePath][0].path, query: this.menusList[this.activePath][0].query });
      } else {
        this.$router.push({ path: '/' + this.menusList[this.activePath][0].path });
      }
      this.saveSecondNavState(this.menusList[this.activePath][0].path)
    },
    saveSecondNavState(activePath) {
      if (activePath == 'zbnote') {
        this.openJs()
        return;
      } else if (activePath == 'ldzy') {
        window.sessionStorage.setItem('activeSecondPath', this.menusList[this.activePath][0].path)
        this.activeSecondPath = this.menusList[this.activePath][0].path
        this.$router.push({ path: '/' + this.menusList[this.activePath][0].path, query: { stageType: 1 } });
        if (!this.userInfo.isAide) {
          this.$message.error("暂无权限")
        } else {
          oauthCode({
            clientId: 'xhly2024855631', username: this.userInfo.username
          }).then(res => {
            if (res.data.data) {
              window.open('https://web.edumate.cn/thirdpart?platform=sxdtly&code=' + res.data.data + '&client_id=xhly2024855631&response_type=code', '_blank')
            } else {
              this.$message.error("授权失败")
            }
          })
        }

        // window.open('http://course.baiyigongfang.com/#/', '_blank')
      } else if (activePath == 'rwmy') {
        window.sessionStorage.setItem('activeSecondPath', this.menusList[this.activePath][0].path)
        this.activeSecondPath = this.menusList[this.activePath][0].path
        this.$router.push({ path: '/' + this.menusList[this.activePath][0].path, query: { stageType: 1 } });
        window.open('https://m.boyameiyu.com/', '_blank')
      } else if (activePath == 'gjb') {
        window.sessionStorage.setItem('activeSecondPath', this.menusList[this.activePath][0].path)
        this.activeSecondPath = this.menusList[this.activePath][0].path
        this.$router.push({ path: '/' + this.menusList[this.activePath][0].path, query: { stageType: 1 } });
        window.open('https://basic.smartedu.cn/', '_blank')
      } else {
        window.sessionStorage.setItem('activeSecondPath', activePath)
        this.activeSecondPath = activePath
      }
    },
    toPage(activePath, activeSecondPath) {
      window.sessionStorage.setItem('activePath', activePath)
      window.sessionStorage.setItem('activeSecondPath', activeSecondPath)
      this.activePath = activePath;
      this.activeSecondPath = activeSecondPath
      this.$router.push({ path: activeSecondPath });
    },
    logout() {
      this.$confirm("您正在退出系统，是否继续？", "退出系统", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$storage.remove("token")
        this.$storage.remove("userInfo")
        this.$router.push({ path: "/login" });
      })
    },
    toHome() {
      this.$router.push("/homePage")
    },
    userCenter() {
      this.$router.push("/user")
    }
  }
}
</script>
<!--内联样式-->
<style lang="scss" scoped>
.tr-img {
  display: flex;
  align-items: center;
  justify-content: end;

  img {
    width: 20px;
    height: 24px;
    margin-left: 15px;
  }
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #c2c0c0;
}

/deep/ .el-menu-item:hover {
  color: #333;
}

/deep/ .el-menu--collapse .el-sub-menu.is-active .el-sub-menu__title {
  color: var(--el-menu-active-color) !important;
}

/deep/ .top-tp .el-menu-item.is-active {
  border-bottom: 2px solid #08c7ff !important;
}

/deep/ .lftop .el-menu-item.is-active::after {
  content: '';
  position: absolute;
  left: 20px;
  width: 3px;
  height: 30%;
  background-color: #08c7ff;
}

/deep/ .lftop .el-menu-item.is-active {
  background: #596cfd;
  position: relative;
}

/deep/ .lftop .el-menu-item.is-active i {
  color: #08c7ff !important;
}

/deep/ .el-menu-item.is-active::after {
  background: #409eff;
  position: absolute;
  right: 0;
  height: 100%;
  width: 2px;
  // content: "";
}

.hamburgerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #e7efff;
}

.hamburger.is-active {
  transform: rotate(180deg);
}

.zbh-logo {
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  // font-weight: bold;
  span {
    white-space: nowrap;
    font-size: 24px;

  }

  div {
    // white-space: nowrap;
    font-size: 24px;

  }

  img {
    width: 25px;
    padding: 0 10px;
  }
}

#home-box {
  width: 100%;
  height: 100%;

  .el-menu--horizontal {
    // border-bottom: 5px solid #409eff;
    border-color: transparent;
    height: 60px;
  }

  .el-main {
    // background: #f7f8f8;
    padding: 0 !important;
    background: #f1f1f1;
  }

  .el-container {
    height: 100%;
  }

  .el-aside {
    width: 20%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    background: #f1f1f1;

    .zbh-logo {
      height: 8%;
      color: #F9F8F8;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
      background-color: #20222a;
    }

    .el-menu {
      border-top-right-radius: 6px;
      overflow: hidden;
      border: none;
      // height: calc(100vh - 66px);
      height: calc(100vh - 75px);
      margin-top: 15px;
      // margin-top: 5px;
      background-color: #dfe4f7;
      color: #fff;
    }

  }

  .el-header {
    padding: 0 0;
    text-align: center;
    height: 60px !important;
    // width: 99% !important;
    // border-bottom: 5px solid #409eff;

    .el-row {
      height: 60px !important;
      padding-right: 7px;
    }

    .el-dropdown {
      // margin-left: 10px !important;
    }
  }
}

.schoolDrawer /deep/ .el-drawer.ttb {
  width: 20%;
  margin-left: 70% !important;
}

.scrollbar-flex-content {
  display: flex;
  height: auto !important;
}

.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-danger-light-9);
  color: var(--el-color-danger);
}
</style>
