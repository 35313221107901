<template>
    <div class="app-container">
        <el-card class="mb15" v-has-btn="'1,2'">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button v-has-btn="'1,2'" type="primary" @click="handleAdd">新增通知banner</el-button>
                    <!-- <el-button type="primary" @click="hadleDz">铺设点阵</el-button> -->
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <el-col :span="16" class="mb0">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.title" placeholder="请输入banner标题" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                :height="`calc(100vh - ${this.$store.state.zdy.role != 1 && this.$store.state.zdy.role != 2?235:320}px)`" border v-loading="loading" :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column width="350" label="banner标题" prop="title">
                        <template #default="scope">
                            <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.title }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="90" label="状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">未发布</div>
                            <div v-if="scope.row.status == 1">已发布</div>
                            <div v-if="scope.row.status == 2">隐藏</div>
                        </template>
                    </el-table-column>

                    <el-table-column width="140" label="发布人账号" prop="releaseUsername" />
                    <el-table-column width="80" label="发布人" prop="releaseName" />
                    <el-table-column width="" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column v-has-btn="'1,2'" fixed="right" width="350" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="handleTop(scope.row)">查看
                        </el-button> -->
                            <el-button size="mini" type="primary" icon="" @click="handleTop(scope.row)">置顶
                            </el-button>
                            <el-button v-if="scope.row.status == 0 || scope.row.status == 2" size="mini" type="primary"
                                icon="" @click="handleRelease(scope.row)">发布
                            </el-button>
                            <el-button v-if="scope.row.status == 1" size="mini" type="primary" icon=""
                                @click="handleHide(scope.row)">隐藏
                            </el-button>
                            <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleUpdate(scope.row)">编辑
                            </el-button>

                            <el-popconfirm width="160px" confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该banner" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button size="mini" type="danger" icon="el-icon-delete">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import { noticeBannerListAll, noticeBannerRelease, noticeBannerDelete, noticeBannerHide, noticeBannerTopping } from "@/api/admin/news";
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop, print } from "@/api/adminservice/form"

export default {
    name: "bannerNotice",
    components: {
    },
    data() {
        return {
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '隐藏', value: 2 },
            ],
            yfbNum: 0,
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                title: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: []
        };
    },
    created() {
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        handleTop(row) {
            noticeBannerTopping({ bannerId: row.bannerId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("置顶成功");
                    this.getList();
                } else {
                    this.$message.error("置顶失败");
                }
            })
        },
        hadleDz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status == 4 || this.selectObj.status == 5) {
                this.$message.error("发布和停用的数据不能铺设");
                return;
            }
            print({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.getList();
                } else {
                    this.$message.error("铺设失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.yfbNum = 0;
            noticeBannerListAll().then(res => {
                if(this.$storage.get("role") == 3 || this.$storage.get("role") == 4 || this.$storage.get("role") == 5 || this.$storage.get("role") == 6 || this.$storage.get("role") == 7){
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status != 2
                    })
                }
                if (this.queryParams.status !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status == this.queryParams.status
                    })
                }
                if (this.queryParams.title !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.title.toString().indexOf(this.queryParams.title) != -1
                    })
                }
                res.data.data.forEach(e => {
                    if (e.status === 1) {
                        this.yfbNum++;
                    }
                })
                this.dataList = res.data.data;
                this.loading = false;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            noticeBannerDelete({ bannerId: row.bannerId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/bannerNoticeDetail",
                query: {
                    data: JSON.stringify(row),
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/bannerNoticeAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleHide(row) {
            noticeBannerHide({ bannerId: row.bannerId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("隐藏成功");
                    this.getList();
                } else {
                    this.$message.error("隐藏失败");
                }
            })
        },
        handleRelease(row) {
            if (this.yfbNum >= 5) {
                this.$message.error("最多只允许发布5个。");
                return;
            } else {
                noticeBannerRelease({ bannerId: row.bannerId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("发布成功");
                        this.getList();
                    } else {
                        this.$message.error("发布失败");
                    }
                })
            }
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/bannerNoticeAdd",
                query: {
                    data: JSON.stringify(row),
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>